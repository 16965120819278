import React, { useState, useCallback, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import type { EmblaCarouselType } from 'embla-carousel';

import {
    EmblaCarousel,
    EmblaContainer,
    Slide,
    Controls,
    PrevNextWrapper,
    PrevButton,
    NextButton,
    PrevIcon,
    NextIcon,
    Pagination,
    PaginationButton
} from './Carousel.styled';

interface SlideBreakpoint {
    slidesToShow: number;
    gap: number;
}

export interface SlidesConfig {
    large: SlideBreakpoint;
    medium: SlideBreakpoint;
    small: SlideBreakpoint;
}

export interface Slide {
    id: string;
    element: React.ReactNode;
}

export interface Props {
    id: string;
    slides: Slide[];
    slidesConfig?: SlidesConfig;
    colours?: {
        primary: string;
        secondary: string;
    };
}

const defaultSlidesToShow: SlidesConfig = {
    large: {
        slidesToShow: 3.5,
        gap: 1
    },
    medium: {
        slidesToShow: 2.5,
        gap: 1
    },
    small: {
        slidesToShow: 1.5,
        gap: 1
    }
};

const Carousel: React.FC<Props> = props => {
    const { id, slides, slidesConfig = defaultSlidesToShow, colours } = props;

    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: true,
        align: 'start',
        inViewThreshold: 0.8
    });
    const [slidesInView, setSlidesInView] = useState<number[]>([]);

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
    const scrollTo = useCallback(
        (index: number) => emblaApi && emblaApi.scrollTo(index),
        [emblaApi]
    );

    const onSlidesInView = useCallback((emblaApi: EmblaCarouselType) => {
        setSlidesInView(emblaApi.slidesInView());
    }, []);

    const resetSlides = useCallback((emblaApi: EmblaCarouselType) => {
        emblaApi.scrollTo(0);
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        onSlidesInView(emblaApi);
        emblaApi.on('slidesInView', onSlidesInView);
        emblaApi.on('resize', resetSlides);
    }, [emblaApi, onSlidesInView, resetSlides]);

    return (
        <>
            <EmblaCarousel id={id} ref={emblaRef}>
                <EmblaContainer>
                    {slides.map(({ id, element }, index) => (
                        <Slide
                            key={id}
                            $slidesToShow={slidesConfig}
                            $inView={slidesInView.includes(index)}
                            data-in-view={slidesInView.includes(index)}
                        >
                            {element}
                        </Slide>
                    ))}
                </EmblaContainer>
            </EmblaCarousel>
            {slidesInView.length !== slides.length && (
                <Controls
                    style={
                        {
                            '--carousel-primary': colours?.primary,
                            '--carousel-secondary': colours?.secondary
                        } as React.CSSProperties
                    }
                >
                    <PrevNextWrapper>
                        <PrevButton aria-label="Previous slide" onClick={scrollPrev}>
                            <PrevIcon />
                        </PrevButton>
                        <NextButton aria-label="Next slide" onClick={scrollNext}>
                            <NextIcon />
                        </NextButton>
                    </PrevNextWrapper>
                    <nav aria-label={`${id} nav`}>
                        <Pagination>
                            {slides.map((item, index) => (
                                <li key={item.id}>
                                    <PaginationButton
                                        aria-label={`Go to slide ${index + 1}`}
                                        aria-current={slidesInView.includes(index)}
                                        $inView={slidesInView.includes(index)}
                                        onClick={() => scrollTo(index)}
                                    />
                                </li>
                            ))}
                        </Pagination>
                    </nav>
                </Controls>
            )}
        </>
    );
};

export default Carousel;
