import styled, { css } from 'styled-components';

import { ReactComponent as CircleSVG } from 'Public/graphics/studentWork/student-work-circles.svg';

export const Wrapper = styled.section`
    ${({ theme }) => css`
        position: relative;
        padding: 3rem 0 5rem;
        background-color: ${theme.colors.secondary.tints[8].hex};
        margin-bottom: 1.75rem;

        @media ${theme.breakpoints.large.media} {
            padding: 7.5rem 0;
            margin-bottom: 3rem;
        }
    `}
`;

export const Background = styled(CircleSVG)`
    position: absolute;
    height: 100%;
    width: 102rem;
    max-width: 100%;
    top: 0;
    right: 50%;
    transform: translateX(50%);
`;

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        color: ${theme.colors.primaryContrast.hex};
        margin-top: 0;
    `}
`;

export const Description = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.primaryContrast.hex};
    `}
`;
